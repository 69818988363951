/* globals Parsley */

import 'parsleyjs';

$.extend(Parsley.options, {
    errorClass: 'is-invalid',
    successClass: 'is-valid',
    errorsWrapper: '<span class="invalid-feedback"></span>',
    errorTemplate: '<div></div>',
    trigger: 'change',
    excluded: 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden',
});

Parsley
    .addValidator('validPassword', {
        requirementType: 'string',
        validateString(value, requirement) {
            requirement = new RegExp(requirement || '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])');
            return requirement.test(value);
        },
        messages: {
            en: 'Your password must include at least one uppercase letter, one lowercase letter and a number',
        },
    })
    .addValidator('phone', {
        requirementType: 'string',
        validateString(value) {
            return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value);
        },
        messages: {
            en: 'This value should be a valid phone number.',
        },
    })
    .addValidator('zip', {
        requirementType: 'string',
        validateString(value, selector) {
            const $countryField = $(`${selector}:visible`);
            const country = $countryField.val();
            switch (country) {
                case 'United States':
                    return /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
                case 'Canada':
                    return /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(value);
                default:
                    return true;
            }
        },
    })
    .addValidator('lt', {
        validateString(val, selector) {
            if (!$(selector).length) return true;
            if (!$(selector).is(':visible')) return true;
            return parseFloat(val) < parseFloat($(selector).val() || 0);
        },
    })
    .addValidator('lte', {
        validateString(val, selector) {
            if (!$(selector).length) return true;
            if (!$(selector).is(':visible')) return true;
            return parseFloat(val) <= parseFloat($(selector).val() || 0);
        },
    })
    .addValidator('gt', {
        validateString(val, selector) {
            if (!$(selector).length) return true;
            if (!$(selector).is(':visible')) return true;
            return parseFloat(val) > parseFloat($(selector).val() || 0);
        },
    })
    .addValidator('gte', {
        validateString(val, selector) {
            if (!$(selector).length) return true;
            if (!$(selector).is(':visible')) return true;
            return parseFloat(val) >= parseFloat($(selector).val() || 0);
        },
    })
    .addValidator('date', {
        requirementType: 'string',
        validateString(value) {
            if (!value) return false;
            let d;
            try {
                d = new Date(value);
            } catch (err) {
                return false;
            }
            if (d.toString() === 'Invalid Date') return false;
            return true;
        },
        messages: {
            en: 'This is not a valid date',
        },
    })
    .addValidator('ltdate', {
        validateString(val, selector) {
            if (!$(selector).length) return true;
            if (!$(selector).is(':visible')) return true;
            try {
                val = new Date(val);
            } catch (e) {
                val = new Date();
            }
            try {
                selector = new Date($(selector).val());
            } catch (e) {
                selector = new Date();
            }
            return val < selector;
        },
    })
    .addValidator('ltedate', {
        validateString(val, selector) {
            if (!$(selector).length) return true;
            if (!$(selector).is(':visible')) return true;
            try {
                val = new Date(val);
            } catch (e) {
                val = new Date();
            }
            try {
                selector = new Date($(selector).val());
            } catch (e) {
                selector = new Date();
            }
            return val <= selector;
        },
    })
    .addValidator('gtdate', {
        validateString(val, selector) {
            if (!$(selector).length) return true;
            if (!$(selector).is(':visible')) return true;
            try {
                val = new Date(val);
            } catch (e) {
                val = new Date();
            }
            try {
                selector = new Date($(selector).val());
            } catch (e) {
                selector = new Date();
            }
            return val > selector;
        },
    })
    .addValidator('gtedate', {
        validateString(val, selector) {
            if (!$(selector).length) return true;
            if (!$(selector).is(':visible')) return true;
            try {
                val = new Date(val);
            } catch (e) {
                val = new Date();
            }
            try {
                selector = new Date($(selector).val());
            } catch (e) {
                selector = new Date();
            }
            return val >= selector;
        },
    });

export default Parsley;

/**
 * Need to make sure the FSP URLs have the same subdomain as the local page.
 * When we make requests to the FSP site that require sharing cookies, we need to make sure they're not seen as
 * a cross-domain request.
 */
function normalizeFspUrl(url) {
    const hasWww = location.href.includes('//www.');
    if (hasWww) return url;
    return url.replace('//www.', '//');
}

export default normalizeFspUrl;
